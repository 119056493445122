<template>
  <v-app>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
.v-main {
  background-color: #82c3c0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
</style>
